.login-backdrop {
  background: linear-gradient(180deg, #7f354e, #fff);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center
}

.login-card {
  background: #fff;
  width: 40vw;
  height: 60vh;
  border-radius: 8px
}

.login-card-header {
  background: #7f354e;
  width: 10vw;
  height: 10vw;
  border-radius: 5vw;
  margin: auto;
  margin-top: -5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
